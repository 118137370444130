/* resume button */ 

#about .resume_button1 {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 2;
	grid-row-end: 2;
	width: 142px;
	background-color: #333;
	margin-top: 30px;
	text-align: center;
}

#about .hvr-sweep-to-right {
    border-width: 2px;
    border-style: solid;
    text-decoration: none;
    margin-bottom: 15px;
    padding: .23em;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: bold;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
#about .hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fe6928;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
#about .hvr-sweep-to-right:hover {
    color: white;
}

#about a, #about a:visited, #about a:active, #about a:focus, #about a:link {
    text-decoration: none;
    color: #fff;
}

#about .hvr-sweep-to-right:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

#about #resume_button2 {
	display: none;
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 2;
	grid-row-end: 2;
	width: 142px;
	text-align: center;
	background-color: #333;
}

/* rest */ 

.row_about {
	width: 30%;
	max-width: 1135px;
	margin: 0 auto;
}

.container1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.abouttitle {
	text-align: center;
}

.abouttext {
	max-width: 90%;
	text-align: left;
	padding-top: 30px;
	padding-left: 0;
}

@media only screen and (max-width: 767px) {
	.row_about {
		width: 70%;
	}

	.container1 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.abouttitle {
		margin-bottom: 15px;
		padding-left: 0;
		letter-spacing: 2px;
	}

	#profilepic_container {
		max-width: 50%;
		margin-bottom: 20px;
	}

	#about .resume_button1 {
		display: none;
	}

	#about #resume_button2 {
		display: block;
		margin: 0 auto;
		margin-top: 20px;
	}
}

@media only screen and (max-width: 979px) {
	.row_about {
		width: 50%;
	}
}